import { Transcript, TranscriptItem } from '@/entities/transcript';

export function toTxt(transcript: Transcript): Blob {
  const str = transcript.transcript;
  return new Blob([str], { type: 'text/plain' });
}

export function toJson(transcript: Transcript): Blob {
  const str = JSON.stringify(transcript);
  return new Blob([str], { type: 'text/json' });
}

type Phrase = TranscriptItem[];

export function toVtt2(transcript: Transcript): Blob {
  const max_length = 42; // Maximum character length for an individual VTT segment/phrase
  const min_duration = 3.0; // Minimum phrase length duration in seconds

  const items: TranscriptItem[] = transcript.items;

  // Determine phrase boundaries based on max length and min duration
  const phrases: Phrase[] = [];
  let current_phrase: TranscriptItem[] = [];
  let current_length = 0;
  let last_word_end_time = 0;

  for (const item of items) {
    const content = item.alternatives[0].content;
    current_length += content.length + 1; // Include space after each word

    if (
      current_length > max_length ||
      (last_word_end_time !== 0 && item.start - last_word_end_time > min_duration)
    ) {
      // Phrase boundary based on max length or min duration
      if (current_phrase.length > 0) {
        phrases.push(current_phrase);
        current_phrase = [];
        current_length = 0;
      }
    }

    // Add word to the current phrase
    current_phrase.push(item);
    last_word_end_time = item.end;
  }

  // If there are any remaining words, add them as the last phrase
  if (current_phrase.length > 0) {
    phrases.push(current_phrase);
  }

  // Get the last valid word's end_time from the JSON file
  let last_word_end_time_json = 0;
  for (let i = items.length - 1; i >= 0; i--) {
    const last_word = items[i];
    if (last_word.end != null && last_word.start != null && last_word.type !== 'punctuation') {
      last_word_end_time_json = last_word.end;
      break;
    }
  }

  // Generate the VTT file structure and format the phrases
  let vtt_content = 'WEBVTT\n\n';
  phrases.forEach((phrase, index) => {
    const start_time = phrase[0].start;
    const end_time = phrase[phrase.length - 1].end || last_word_end_time_json; // Use the last_word_end_time as the end time
    const start_ms = Math.floor(start_time * 1000);
    let end_ms = Math.floor(end_time * 1000);

    // Handle the case when end time is "00:00:00.000"
    if (end_ms === 0) {
      const next_phrase = phrases[index + 1];
      if (next_phrase) {
        const next_start_time = next_phrase[0].start;
        end_ms = Math.floor(next_start_time * 1000);
      }
    }

    let phrase_text = phrase.map((item) => item.alternatives[0].content).join(' ');
    // Add the punctuation at the end of the phrase
    if (['.', '!', '?'].includes(phrase_text[phrase_text.length - 1])) {
      phrase_text = phrase_text.slice(0, -1); // Remove punctuation from the end
      // end_ms -= 500; // Reduce the end time by 500ms to place the punctuation at the end
    }

    vtt_content += `${index + 1}\n${msToVttTimestamp(start_ms)} --> ${msToVttTimestamp(
      end_ms,
    )}\n${phrase_text}\n\n`;
  });

  return new Blob([vtt_content], { type: 'text/vtt' });

  // Save the generated VTT file
}

export function toVtt(transcript: Transcript): Blob {
  // VTT PHRASING WITH MAX CHAR LENGTH AND MIN DURATION

  // Set the maximum character length and minimum duration manually
  const maxLength = 42; // Maximum character length for an individual VTT segment/phrase
  const minDuration = 3.0; // Minimum phrase length duration in seconds

  // Extract the word-level items from the JSON
  const items = transcript.items;

  // Determine phrase boundaries based on max length and min duration
  const phrases: Phrase[] = [];
  let currentPhrase: Phrase = [];
  let currentLength = 0;
  let currentStartTime = 0;
  for (const item of items) {
    const content = item.alternatives[0]?.content;
    currentLength += content.length + 1; // Include space after each word
    if (['.', '!', '?'].includes(content)) {
      // Phrase boundary detected (e.g., at punctuation marks)
      if (currentPhrase.length > 0) {
        phrases.push(currentPhrase);
        currentPhrase = [];
        currentLength = 0;
        currentStartTime = 0;
      }
    } else if (
      currentLength > maxLength ||
      (currentStartTime !== 0 && item.start - currentStartTime > minDuration)
    ) {
      // Phrase boundary based on max length or min duration
      if (currentPhrase.length > 0) {
        phrases.push(currentPhrase);
        currentPhrase = [];
        currentLength = 0;
        currentStartTime = 0;
      }
    }

    // Add word to the current phrase
    currentPhrase.push(item);
    if (currentStartTime === 0) {
      currentStartTime = item.start;
    }
  }

  // If there are any remaining words, add them as the last phrase
  if (currentPhrase.length > 0) {
    phrases.push(currentPhrase);
  }

  // Generate the VTT file structure and format the phrases
  let vttContent = 'WEBVTT\n\n';
  phrases.forEach((phrase, index) => {
    const startTime = phrase[0].start;
    const endTime = phrase[phrase.length - 1].end;
    const startMs = Math.floor(startTime * 1000);
    let endMs = Math.floor(endTime * 1000);

    // Handle the case when end time is "00:00:00.000"
    if (endMs === 0) {
      if (index < phrases.length - 1) {
        const nextPhrase = phrases[index + 1];
        const nextStartTime = nextPhrase[0].start;
        endMs = Math.floor(nextStartTime * 1000);
      }
    }

    const phraseText = phrase.map((item) => item.alternatives[0].content).join(' ');
    vttContent += `${index + 1}\n${msToVttTimestamp(startMs)} --> ${msToVttTimestamp(
      endMs,
    )}\n${phraseText}\n\n`;
  });

  return new Blob([vttContent], { type: 'text/vtt' });
}

function msToVttTimestamp(milliseconds: number): string {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const formattedMilliseconds = milliseconds % 1000;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}.${formattedMilliseconds.toString().padStart(3, '0')}`;
}
