/* eslint-disable */
import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './StreamKeys.module.scss';
import { useGetStreamKeys } from '@/adapters/stream-keys';
import CopyToClipboard from '@/components/CopyToClipboard';
import PrimaryButton from '@/components/common/PrimaryButton';
// import DefaultButton from '@/components/common/DefaultButton';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import StreamKeyCheckoutModal from '@/components/modals/StreamKeyCheckoutModal';
import { useUpdateStreamSubscription } from '@/adapters/subscription';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '@/components/Loading';
import Api from '../../adapters/api';
import DefaultButton from '../../components/common/DefaultButton';
import { UserContext } from '@/contexts/UserContext';
import { UserSubscriptionState } from '@/entities/subscription';
import { useNavigate } from 'react-router-dom';
import useAuthorization from '@/hooks/useAuthorization';

/* istanbul ignore file */

{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}
const StreamKeys: FC = () => {
  const { data: streamKeyList = { items: [] }, isLoading } = useGetStreamKeys();
  const { items: streamKeys } = streamKeyList;
  const { user } = useAuth0();
  const { hasRole } = useAuthorization();
  const email = user?.email ?? '';

  const [openCheckoutModal, setOpenCheckoutModal] = useState<boolean>(false);
  const [isAnnualMode, setIsAnnualMode] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const { userState } = useContext(UserContext);
  const [state, setState] = useState<UserSubscriptionState[] | null>(null);
  const handleCheckoutModalClose: () => void = () => {
    setOpenCheckoutModal(false);
  };
  const navigate = useNavigate();
  const { mutateAsync: updateStreamSubscription } = useUpdateStreamSubscription();
  const handleUpdateSubscription = async (count: number): Promise<void> => {
    const { updated }: { updated: string } = await updateStreamSubscription({
      email: email,
      lookup_key: 'eos-rtmp-annually',
      quantity: count,
    });

    if (updated) {
      // window.location.reload();
      navigate('/subscription/history');
    }
  };

  useEffect(() => {
    if (userState) {
      setState(
        userState.SubscriptionState.length > 0
          ? userState.SubscriptionState.filter(
              (state) =>
                state.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH ||
                state.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL,
            )
          : [],
      );
    }
  }, [userState]);

  const handleOnChange = async (value: string, id: string) => {
    setName(value);
    const data = {
      id: id,
      name: value,
    };
    await Api.post('/stream-keys', data);
  };

  const handleOnReset = async (id: string) => {
    const data = {
      id: id,
    };
    await Api.post('/stream-keys/reset', data);
    window.location.reload();
  };
  const handleOnDelete = async (id: string) => {
    const data = {
      id: id,
    };
    await Api.delete(`/stream-keys/${id}`);
    window.location.reload();
  };

  return (
    <>
      <div>
        <h1 className={styles.pageTitle}>Stream Keys</h1>
        {!hasRole(['temporary_enterprise', 'enterprise', 'super_admin']) && (
          <div className={styles.subTitles}>
            <p className={styles.pageDescription}>
              To use the stream keys with your favorite streaming service, copy and paste these
              links.
            </p>
            <PrimaryButton
              onClick={() => {
                if (state && state.length > 0) {
                  setOpenCheckoutModal(true);
                } else {
                  localStorage.setItem(
                    'keyError',
                    'Please subscribe to Pro Plan to add Stream Keys to your account',
                  );

                  setTimeout(() => {
                    localStorage.removeItem('keyError');
                  }, 5000);
                  navigate('/subscription/plans');
                }
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '11px', md: '1rem', lg: '1rem' },
                  paddingLeft: '8px',
                  paddingRight: '5px',
                }}
              >
                Add Stream
              </Typography>
              <AddIcon />
            </PrimaryButton>
          </div>
        )}
      </div>
      <div className={styles.table}>
        <div className={styles.tableTitle}>
          <div className={`${styles.tableItem}`}>Stream Name</div>
          <div className={`${styles.tableItem} ${styles.columnType}`}>Type</div>
          <div className={styles.tableItem}>Streaming Server</div>
          <div className={styles.tableItem}>Stream Key</div>
          <div className={`${styles.tableItem} ${styles.columnPlaybackUrl}`}>Playback URL</div>
          <div className={styles.tableItem}>Actions</div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          streamKeys
            .filter((stream) => stream)
            .map((streamKey, index) => {
              // Define your condition here
              const isDisabled = streamKey.isBlackListed;

              return (
                <div
                  className={`${styles.tableBody} ${isDisabled ? styles.disabledRow : ''}`}
                  key={index}
                >
                  <div className={styles.tableBodyTop}>
                    <div className={`${styles.tableItem}`}>
                      <input
                        type="text"
                        defaultValue={streamKey.name ?? streamKey.id}
                        onChange={(_) => handleOnChange(_.target.value, streamKey.id)}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className={`${styles.tableItem} ${styles.columnType}`}>
                      {streamKey.type || 'RTMP'}
                    </div>
                    <div className={styles.tableItem}>
                      <CopyToClipboard text={streamKey.ingestServer} enabled={isDisabled}>
                        <input type="text" value={streamKey.ingestServer} disabled={isDisabled} />
                      </CopyToClipboard>
                    </div>
                    <div className={styles.tableItem}>
                      <CopyToClipboard text={streamKey.streamKey} enabled={isDisabled}>
                        <input type="text" value={streamKey.streamKey} disabled={isDisabled} />
                      </CopyToClipboard>
                    </div>
                    <div className={`${styles.tableItem} ${styles.columnPlaybackUrl}`}>
                      <CopyToClipboard text={streamKey.playbackUrl} enabled={isDisabled}>
                        <input type="text" value={streamKey.playbackUrl} disabled={isDisabled} />
                      </CopyToClipboard>
                    </div>
                    <div className={`${styles.tableItem} ${styles.tableBtn}`}>
                      <DefaultButton
                        size="small"
                        style={{ marginLeft: '5px' }}
                        onClick={() => handleOnReset(streamKey.id)}
                        disabled={isDisabled}
                      >
                        Reset
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              );
            })
        )}
      </div>
      <StreamKeyCheckoutModal
        open={openCheckoutModal}
        isAnnualModeInit={isAnnualMode}
        handleClose={handleCheckoutModalClose}
        setIsAnnualMode={setIsAnnualMode}
        updateSubscription={(count: number) => handleUpdateSubscription(count)}
      />
    </>
  );
};

export default StreamKeys;
