/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */

import Api from '@/adapters/api';
import { useGetUserList } from '@/adapters/users';
import DefaultButton from '@/components/common/DefaultButton';
import PrimaryButton from '@/components/common/PrimaryButton';
import Loading from '@/components/Loading';
import { msToHoursMinutes } from '@/utils';
import { Box, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { useDebouncedCallback } from 'use-debounce';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NoUsers from './NoUsers';
import styles from './Users.module.scss';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid var(--silver)`,
  borderRadius: '8px',
  marginBottom: '15px',
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: 'var(--gallery)',
  borderBottomColor: 'transparent',
  borderRadius: '8px',
  flexDirection: 'row-reverse',
  padding: 0,
  '&.Mui-expanded': {
    borderRadius: '8px 8px 0 0',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: '10px',
  },
}));

{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}

export const EnterpriseMap: any = {
  temporary_enterprise: 'TemporaryEnterprise',
  enterprise: 'Enterprise',
  standard: 'Standard',
  pro: 'Pro',
  super_admin: 'Super Admin',
};

const Users: FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');

  const { data: users = [], isLoading, refetch } = useGetUserList(searchedValue);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    const targetPath = '/users/create';
    if (location.pathname !== targetPath) {
      navigate(targetPath);
    }
  };

  const handleUpdate = async (id: string) => {
    await Api.patch(`user/update/${id}`, { role: 'enterprise' });
    refetch();
  };

  const handleEditPage = async (email: string) => {
    const targetPath = `/users/${email}`;
    if (location.pathname !== targetPath) {
      navigate(targetPath);
    }
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    setSearchedValue(value);
  }, 500);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <>
      <div className={styles.navbar}>
        <h1 className={styles.projectsHeadTitle}>Manage Users</h1>
        <div className={styles.searchBar}>
          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search"
          />
          <SearchIcon sx={{ color: '#7a7a7a', position: 'absolute', right: '16px', top: '7px' }} />
        </div>

        <PrimaryButton
          onClick={() => {
            handleNavigate();
          }}
        >
          <div
            className={styles.buttonLabel + ' ' + styles.backButtonLabel}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Create
          </div>
        </PrimaryButton>
      </div>
      {isLoading ? (
        <Loading />
      ) : users.length === 0 ? (
        <NoUsers text={'No Users'} />
      ) : (
        <>
          {
            <>
              <div className={styles.table}>
                <div className={styles.tableTitle}>
                  <div className={styles.tableItem}>User Email</div>
                  <div className={styles.tableItem}>Name</div>
                  <div className={styles.tableItem}>Role</div>
                  <div className={styles.tableItem}>Stream Duration</div>
                  <div className={styles.tableItem}>Upload Duration</div>
                  <div className={styles.tableItem}>Action</div>
                </div>
                {users.map((user) => {
                  const totalDuration = user?.streamDuration.reduce(
                    (acc, stream) => acc + stream.duration,
                    0,
                  );

                  return (
                    <Accordion key={user.id}>
                      <AccordionSummary>
                        <Typography
                          sx={{ flex: '2 1', padding: 0 }}
                          className={styles.tableItem + ' ' + styles.specialColumn}
                        >
                          {user.email}
                        </Typography>
                        <Typography
                          sx={{ flex: '1 1', padding: 0 }}
                          className={styles.specialColumn}
                        >
                          {user.name}
                        </Typography>
                        <Typography
                          sx={{ flex: '1 1', padding: 0 }}
                          className={styles.specialColumn}
                        >
                          {EnterpriseMap[`${user.role}`]}
                        </Typography>
                        <Typography
                          sx={{ flex: '1 1', padding: '0 0 0 5px' }}
                          className={styles.specialColumn}
                        >
                          {msToHoursMinutes(totalDuration)}
                        </Typography>
                        <Typography
                          sx={{ flex: '1 1', padding: 0 }}
                          className={styles.specialColumn}
                        >
                          {msToHoursMinutes(parseInt(user.uploadDuration))}
                        </Typography>

                        <Box
                          sx={{ flex: '1 2', padding: 0, display: 'flex' }}
                          className={styles.tableBtn}
                        >
                          {(user.role === 'temporary_enterprise' || user.role === 'pro') && (
                            <>
                              <DefaultButton
                                size="small"
                                sx={{ paddingLeft: 1, paddingRight: 1 }}
                                onClick={() => handleUpdate(user.id)}
                              >
                                Upgrade to Enterprise
                              </DefaultButton>
                              <DefaultButton
                                size="small"
                                sx={{ marginLeft: 1, paddingLeft: 1, paddingRight: 1 }}
                                onClick={() => handleEditPage(user.email)}
                              >
                                Edit
                              </DefaultButton>
                            </>
                          )}
                        </Box>
                      </AccordionSummary>
                    </Accordion>
                  );
                })}

                <div className={styles.tableTitle}>
                  <div className={styles.tableItem}>User Email</div>
                  <div className={styles.tableItem}>Name</div>
                  <div className={styles.tableItem}>Role</div>
                  <div className={styles.tableItem}>Stream Duration</div>
                  <div className={styles.tableItem}>Upload Duration</div>
                  <div className={styles.tableItem}>Action</div>
                </div>
              </div>
            </>
          }
        </>
      )}
    </>
  );
};
export default Users;
