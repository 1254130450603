import { Email } from '@mui/icons-material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
} from '@mui/material';
import { FC, useCallback, useContext } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import { useGetProjectShareUrls } from '@/adapters/projects';
import DriveLogo from '@/assets/images/drive-icon.svg';
import JWPLogo from '@/assets/images/jwpLogo.svg';
import WowzaIcon from '@/components/Icons/WowzaIcon';
import { ProjectContext } from '@/contexts/ProjectContext';
import { VideoContext } from '@/contexts/VideoContext';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import ShareButton from './ShareButton';
import { ShareButtons } from './styles';
import useAuthorization from '@/hooks/useAuthorization';

interface ShareDialogProps {
  open: boolean;
  onClose(): void;
  onShareByWowza(): void;
  onShareByJwp(): void;
  onShareByDrive(): void;
  wowza?: boolean;
  jwp?: boolean;
  drive?: boolean;
}

// const WOWZA_EMAILS = [
//   'webcast@encore-anzpac.com',
//   'editonthespot@gmail.com',
//   'giang@editonthespot.ai',
//   'team@editonthespot.ai',
// ];

const ShareDialog: FC<ShareDialogProps> = ({
  open,
  onShareByWowza,
  onShareByJwp,
  onShareByDrive,
  onClose,
  wowza,
  jwp,
  drive,
}) => {
  // const { user } = useAuth0();
  const { project } = useContext(ProjectContext);
  const { video } = useContext(VideoContext);
  const { videoId } = useParams<{ projectId: string; videoId: string }>();
  const { hasRole } = useAuthorization();

  const { data, isLoading } = useGetProjectShareUrls(project?.id || '');

  const shareUrl = data?.edits.find((e) => e.name === videoId?.replaceAll('-', ' '))?.url || '';

  const shareByEmail = useCallback((): void => {
    const subject = 'Your video is ready.';
    const body = `${shareUrl} Video was edited with Edit on the Spot. Please note, the link will expire in 5 days.`;
    let uri = 'mailto:?subject=';
    uri += encodeURIComponent(subject);
    uri += '&body=';
    uri += encodeURIComponent(body);
    window.open(uri);
  }, [shareUrl]);

  const shareByWowza = useCallback(() => {
    if (!project || !video) {
      return;
    }

    onShareByWowza();
  }, [project, video, onShareByWowza]);

  const shareByJwp = useCallback(() => {
    if (!project || !video) {
      return;
    }

    onShareByJwp();
  }, [project, video, onShareByWowza]);

  const shareByDrive = useCallback(() => {
    if (!project || !video) {
      return;
    }

    onShareByDrive();
  }, [project, video, onShareByDrive]);

  const copyUrl = useCallback(async () => {
    await navigator.clipboard.writeText(shareUrl);
  }, [shareUrl]);

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontSize: 16 }}>Share</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress></CircularProgress>
          </Box>
        ) : (
          <>
            <ShareButtons>
              <ShareButton
                icon={<Email></Email>}
                label={'Email'}
                onClick={() => shareByEmail()}
              ></ShareButton>
              {wowza && (
                <ShareButton
                  icon={<WowzaIcon></WowzaIcon>}
                  label={'Wowza'}
                  onClick={() => shareByWowza()}
                ></ShareButton>
              )}
              {jwp && (
                <ShareButton
                  icon={<WowzaIcon></WowzaIcon>}
                  src={JWPLogo}
                  label={'Jwp'}
                  onClick={() => shareByJwp()}
                ></ShareButton>
              )}
              {hasRole(['pro']) && drive && (
                <ShareButton
                  icon={<WowzaIcon></WowzaIcon>}
                  src={DriveLogo}
                  label={'Drive'}
                  onClick={() => shareByDrive()}
                ></ShareButton>
              )}
            </ShareButtons>
            <OutlinedInput
              value={shareUrl}
              fullWidth
              size="small"
              endAdornment={
                <IconButton onClick={copyUrl}>
                  <ContentCopy />
                </IconButton>
              }
            ></OutlinedInput>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
