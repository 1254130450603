/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from 'react';
// import { FC } from 'react';
import { Box } from '@mui/system';
// import { audioData } from '@/data/segmentData';

import { usePeaksInstance } from '@/hooks/usePeaksInstance';
import { useWaveform } from '@/hooks/useWaveform';
import { SegmentDragEvent, SegmentMouseEvent } from 'peaks.js';
import DefaultButton from '../common/DefaultButton';
import ClipGrid from '@/components/Waveform/ClipGrid';
import ClipGridHeader from '@/components/Waveform/ClipGridHeader';
import InvalidTCPositionModal from '@/components/Waveform/InvalidTCPositionModal';
import { TestSegmentProps } from '@/types';
import { useProcessTopAndTail, useProcessTopAndTailBatch, useEditSegment } from '@/adapters/edits';
import { useNavigate, useParams } from 'react-router-dom';
import Project from '@/types/project';
import ExpandIcon from '@/assets/images/upper-arrow.svg';
import CollapseIcon from '@/assets/images/down-arrow.svg';
import { Tooltip } from '@mui/material';
import { UserContext } from '@/contexts/UserContext';
import { UserSubscriptionState } from '@/entities/subscription';
import { TopAndTailPayload } from '@/entities/timing';

const zoomviewWaveformRef = React.createRef<HTMLDivElement>();
const overviewWaveformRef = React.createRef<HTMLDivElement>();
const audioElementRef = React.createRef<HTMLVideoElement>();

{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}

const Waveform: React.FC<{
  audioUrl: any;
  timeStamp: number | null;
  project: Project | undefined;
  setTimeStamp?(time: number): void;
}> = ({ audioUrl, timeStamp, project, setTimeStamp }) => {
  //create references to peaks.js containers

  const [currSegment, setCurrSegment] = useState<TestSegmentProps | undefined>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [duration, setDuration] = useState<any>(null);
  const navigate = useNavigate();
  const { userState } = useContext(UserContext);
  const [state, setState] = useState<UserSubscriptionState[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();
  const [topntail] = useState<any[] | undefined>(
    project?.edits.filter((seg) => seg.id === 'top-and-tail'),
  );
  const { mutateAsync: processTopAndTail } = useProcessTopAndTail(projectId!);
  const { mutateAsync: processTopAndTailBatch } = useProcessTopAndTailBatch(projectId!);
  const { mutateAsync: editSegment } = useEditSegment(projectId!);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  // const params = useParams();

  //custom hook to initialise a peaks instance with reference to component elements
  const { isReady, myPeaks, segments, setSegments, initPeaks } = usePeaksInstance(
    zoomviewWaveformRef,
    overviewWaveformRef,
    audioElementRef,
    project?.audio?.waveFormData ?? '',
  );

  // custom hook to return waveform utilitiy functions
  const {
    createGenericTopTail,
    editClipStartPoint,
    editClipEndPoint,
    handleAddSegment,
    deleteAllSegments,
    // createAllSegments,
    handlePlayheadSeek,
    deleteSingleSegment,
    createSingleSegment,
    handleFileNameChange,
    clipOverlap,
    // loadSegments,
  } = useWaveform(project, myPeaks!, segments, setSegments, duration, setCurrSegment);

  const handleClipDragEnd = (evt: SegmentDragEvent): void => {
    evt.startMarker ? editClipStartPoint(evt) : editClipEndPoint(evt);
  };

  //Adds a new segment to the zoomview on double clicked
  // eslint-disable-next-line
  const handleZoomviewDblClick = () => {
    segments.length >= 1 &&
      handleAddSegment(() => {
        console.log('Open');
      });
  };
  const setPeaks = useCallback((): void => {
    if (timeStamp) {
      myPeaks?.player.seek(timeStamp);
      if (currSegment) {
        if (timeStamp < currSegment.endTime) {
          const payload = {
            segment: {
              ...currSegment,
              startTime: timeStamp,
            },
          };
          editClipStartPoint(payload);
        } else {
          const payload = {
            segment: {
              ...currSegment,
              endTime: timeStamp,
            },
          };
          editClipEndPoint(payload);
        }
      }
    }
  }, [timeStamp]);

  const createBatchSegments = async (): Promise<void> => {
    const payload: TopAndTailPayload[] = [];
    const filteredSegments = segments.filter((seg) => seg.status === 'Staging');
    if (filteredSegments.length > 1) {
      filteredSegments.forEach((seg) => {
        payload.push({
          end: seg.endTime,
          start: seg.startTime,
          enhanceVideo: false,
          name: seg.fileName,
        });
      });
      if (payload && payload.length > 1) {
        try {
          setIsSaving(true);
          await processTopAndTailBatch(payload);
        } finally {
          window.location.reload();
          setIsSaving(false);
        }
      }
    }
  };

  //////////////////////////////////////////////////////////////////////

  const handleOverviewClicked = (): void => {
    if (currSegment) {
      if (myPeaks!.player.getCurrentTime() < currSegment.startTime) {
        const payload = {
          ...currSegment,
          startTime: myPeaks!.player.getCurrentTime(),
        };
        setTimeStamp && setTimeStamp(myPeaks!.player.getCurrentTime());
        editClipStartPoint({ segment: payload });
      } else {
        const payload = {
          ...currSegment,
          endTime: myPeaks!.player.getCurrentTime(),
        };
        setTimeStamp && setTimeStamp(myPeaks!.player.getCurrentTime());
        editClipEndPoint({ segment: payload });
      }
    } else {
      setTimeStamp && setTimeStamp(myPeaks!.player.getCurrentTime());
    }
  };

  useEffect(() => {
    //event handlers
    myPeaks?.on('segments.dragend', handleClipDragEnd);
    myPeaks?.on('overview.click', handleOverviewClicked);
    myPeaks?.on('zoomview.click', handleOverviewClicked);
    myPeaks?.on('segments.click', (evt: SegmentMouseEvent) => {
      handlePlayheadSeek(evt.segment.id);
    });
    myPeaks?.on('zoomview.dblclick', handleZoomviewDblClick);
    myPeaks?.on('overview.dblclick', handleZoomviewDblClick);

    return () => {
      //cleanup
      myPeaks?.off('segments.dragend', handleClipDragEnd);
      myPeaks?.off('overview.click', handleOverviewClicked);
      myPeaks?.off('zoomview.click', handleOverviewClicked);
      myPeaks?.off('zoomview.dblclick', handleZoomviewDblClick);
      myPeaks?.off('overview.dblclick', handleZoomviewDblClick);
      myPeaks?.off('segments.click', (evt: SegmentMouseEvent) => {
        handlePlayheadSeek(evt.segment.id);
      });
    };
  }, [myPeaks, handleClipDragEnd, handleZoomviewDblClick, handleOverviewClicked, setTimeStamp]);

  useEffect(() => {
    setPeaks();
  }, [setPeaks]);

  // useEffect(() => {
  //   params.projectId && loadSegments(params.projectId);
  // }, []);

  const handleSaveTopTail = async (): Promise<void> => {
    // eslint-disable-next-line no-debugger
    if (currSegment) {
      try {
        setIsSaving(true);
        await processTopAndTail({
          end: currSegment.endTime,
          start: currSegment.startTime,
          enhanceVideo: false,
          name: currSegment.fileName,
        });
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleSegmentNameChange = async (): Promise<void> => {
    // If there has been a change to the fileName send the change

    if (currSegment) {
      if (currSegment.id !== currSegment.fileName) {
        try {
          setIsSaving(true);
          const id = currSegment.id;
          await editSegment({
            name: currSegment.fileName || '',
            segmentId: id,
          });
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  const handleExpansion = (): void => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    initPeaks();
  }, [isExpanded]);

  useEffect(() => {
    const audio = audioElementRef.current;
    if (audio) {
      audio.addEventListener('loadedmetadata', () => {
        setDuration(audio.duration);
      });
      return () => {
        audio.removeEventListener('loadedmetadata', () => {
          setDuration(audio.duration);
        });
      };
    }
  }, []);

  useEffect(() => {
    if (userState) {
      setState(
        userState.SubscriptionState.filter(
          (sub) =>
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
        ),
      );
    }
  }, [userState]);

  return (
    <Box
      sx={{
        m: 0,
        p: 0,
        width: '100%',
      }}
    >
      <InvalidTCPositionModal
        isOpen={false}
        onClose={() => {
          //
        }}
        clipOverlap={clipOverlap}
        myPeaks={myPeaks!}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip title={isExpanded ? 'Collapse 10sec zoom' : 'Show 10sec zoom'}>
          <button
            style={{ border: 'none', cursor: 'pointer', background: 'none' }}
            onClick={handleExpansion}
          >
            <img
              src={isExpanded ? CollapseIcon : ExpandIcon}
              alt={isExpanded ? 'CollapseIcon' : ExpandIcon}
            />
          </button>
        </Tooltip>
      </div>
      <div
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {isExpanded && (
          <div
            ref={zoomviewWaveformRef}
            // disableGutters
            style={{
              boxShadow: '3px 3px 20px #919191',
              MozBoxShadow: '3px 3px 20px #919191',
              WebkitBoxShadow: '3px 3px 20px #919191',
              margin: '24px 0 24px 0',
              lineHeight: 0,
              height: '266px',
              width: '100%',
            }}
          ></div>
        )}
        <div
          ref={overviewWaveformRef}
          // disableGutters
          style={{
            boxShadow: '3px 3px 20px #919191',
            MozBoxShadow: '3px 3px 20px #919191',
            WebkitBoxShadow: '3px 3px 20px #919191',
            margin: '0 0 24px 0',
            lineHeight: 0,
            height: '129px',
            width: '100%',
          }}
        ></div>
        {/* <audio ref={audioElementRef} hidden>
          <source src={audioData.audioUrl} type={audioData.audioContentType} />
          Your browser does not support the audio element.
        </audio> */}
        <video ref={audioElementRef} hidden preload="metadata">
          <source src={project?.original?.fullPath} type="video/mp4" />
        </video>
        {/* <audio ref={audioElementRef} hidden controls preload="metadata">
          <source src={audioUrl} type={'audio/mpeg'} />
          Your browser does not support the audio element.
        </audio> */}
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '4rem',
        }}
      >
        <Box>
          <DefaultButton
            disabled={isSaving || !isReady || (topntail && topntail.length === 0)}
            onClick={() => createGenericTopTail()}
            sx={{ margin: '5px' }}
          >
            Add Top-n-Tail
          </DefaultButton>

          <DefaultButton
            onClick={() => {
              if (state.length === 0 && userState && userState.trialDays === 0) {
                navigate('/subscription/plans');
              } else {
                handleAddSegment(() => {
                  // onInvalidTCPModalOpen
                });
              }
            }}
            disabled={isSaving || !isReady}
            sx={{
              margin: '5px',
              opacity:
                Array.isArray(state) && state.length === 0 && userState && userState.trialDays === 0
                  ? '0.4'
                  : '10',
            }}
          >
            Add Segment
          </DefaultButton>
        </Box>
        <Box>
          <DefaultButton
            disabled={
              segments.filter((seg) => seg.status === 'Staging').length < 2 || !isReady || isSaving
            }
            onClick={createBatchSegments}
            sx={{ margin: '5px' }}
          >
            Create All
          </DefaultButton>
          <DefaultButton
            disabled={
              segments.filter((seg) => seg.status === 'Staging').length < 2 || isSaving || !isReady
            }
            onClick={() => deleteAllSegments()}
            sx={{ margin: '5px' }}
          >
            Delete All
          </DefaultButton>
        </Box>
      </Box>
      {segments.length !== 0 ? <ClipGridHeader /> : 'There are no clips loaded'}
      {segments.length > 0 && (
        <ClipGrid
          isReady={isReady}
          segments={segments}
          setSegments={setSegments}
          myPeaks={myPeaks!}
          handlePlayheadSeek={handlePlayheadSeek}
          deleteSingleSegment={deleteSingleSegment}
          createSingleSegment={createSingleSegment}
          saving={isSaving}
          saveTopTail={handleSaveTopTail}
          saveNameChange={handleSegmentNameChange}
          handleFileNameChange={handleFileNameChange}
          editClipStartPoint={(updatedSegment) => editClipStartPoint(updatedSegment)}
          editClipEndPoint={(updatedSegment) => editClipEndPoint(updatedSegment)}
        />
      )}
    </Box>
  );
};

export default Waveform;
