/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { TestSegmentProps } from '../types';
import { PeaksInstance } from 'peaks.js';
import { createNewSegment } from '../lib/general-utils';
import { useParams } from 'react-router-dom';
import Project from '@/types/project';
import { UserSubscriptionState } from '@/entities/subscription';
import { UserContext } from '@/contexts/UserContext';

interface WaveformHookReturnType {
  createGenericTopTail(
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void;
  editClipStartPoint(evt: any): void;
  editClipEndPoint(evt: any): void;
  handleAddSegment(onOpen: () => void): void;
  deleteAllSegments(
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void;
  createAllSegments(): void;
  handlePlayheadSeek(id: string | undefined, seekStart?: boolean): void;
  deleteSingleSegment(
    id: string,
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void;
  createSingleSegment(
    id: string,
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void;
  handleFileNameChange(id: string, evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  clipOverlap: boolean;
  handlePersistSegments(id: string | undefined, segs: TestSegmentProps[]): void;
  loadSegments(): void;
}

export const useWaveform = (
  project: Project | undefined,
  myPeaks: PeaksInstance,
  segments: TestSegmentProps[],
  setSegments: React.Dispatch<React.SetStateAction<TestSegmentProps[]>>,
  duration: any,
  setCurrSegment?: (segment: TestSegmentProps | undefined) => void,
): WaveformHookReturnType => {
  const [state, setState] = useState<UserSubscriptionState[]>([]);
  const { userState } = useContext(UserContext);
  const [clipOverlap, setClipOverlap] = useState<boolean>(false);
  const params = useParams();

  //////////////////////////////////////////////////////////////////////
  //
  //
  //              Create a generic top and tail segment covering
  //              90 % of the media duration
  //
  //
  const createGenericTopTail = (
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void => {
    const topntail = project?.edits.filter((seg) => seg.id === 'top-and-tail');
    if (topntail && topntail.length > 0) {
      const topTailSegment = {
        status: 'Staging',
        id: 'top-n-tail-segment',
        fileName: 'Top-n-Tail-Segment',
        startTime: topntail[0].timing.start, // Start after the specified duration
        endTime: topntail[0].timing.end,
        editable: true,
        color: '#1E1541',
        labelText: 'Top-n-Tail-Segment',
        formErrors: {
          fileNameError: false,
          isCreated: false,
        },
      };
      setCurrSegment && setCurrSegment(topTailSegment);
      callback && callback(params.projectId, [topTailSegment]);
      setSegments([...segments, topTailSegment]);
    }
  };

  useEffect(() => {
    loadSegments();
  }, [project]);

  useEffect(() => {
    if (userState) {
      setState(
        userState.SubscriptionState.filter(
          (sub) =>
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
        ),
      );
    }
  }, [userState]);

  //persist segments

  const handlePersistSegments = (id: string | undefined, segs: TestSegmentProps[]): void => {
    if (id) {
      const savedSegments = localStorage.getItem('segments');
      if (savedSegments) {
        let existing: any[] = JSON.parse(savedSegments);
        const curr = existing.find((seg) => seg.id == id);
        if (curr) {
          existing = existing.map((seg) => {
            if (seg.id == id) {
              return {
                id,
                segments: segs,
              };
            } else {
              return seg;
            }
          });
          localStorage.setItem('segments', JSON.stringify(existing));
        } else {
          existing.push({ id, segments: segs });
          localStorage.setItem('segments', JSON.stringify(existing));
        }
      } else {
        const toSave: any[] = [{ id: id, segments: segs }];
        localStorage.setItem('segments', JSON.stringify(toSave));
      }
    }
  };

  const loadSegments = (): void => {
    if (project) {
      if (project.edits.length > 0) {
        const filtered = project.edits.filter((seg) => seg.id !== 'top-and-tail' && seg.timing);
        const segs: TestSegmentProps[] = filtered.map((seg) => {
          return {
            status: seg.status,
            id: seg.id,
            fileName: seg.name,
            formErrors: {
              fileNameError: false,
              isCreated: true,
            },
            editable: false,
            color: '#48221F',
            startTime: seg.timing.start,
            endTime: seg.timing.end,
          };
        });
        if (segments.length > 0) {
          const toConcat = segments.filter((seg) => seg.status === 'Staging');
          // const filtered = segs.map((seg) => {
          //   const temp = segments.filter((old) => old.fileName !== seg.fileName);
          //   if (temp.length > 0) {
          //     return temp[0];
          //   } else {
          //     return seg;
          //   }
          // });

          setSegments(toConcat.concat(segs));
        } else {
          setSegments(segs);
        }
      }
    }
  };
  // const loadSegments = (id: string): void => {
  //   const existing = localStorage.getItem('segments');
  //   if (existing) {
  //     const segmentsToInit: any[] = JSON.parse(existing);
  //     segmentsToInit.forEach((seg: any) => {
  //       if (seg.id == id) {
  //         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
  //         const update: TestSegmentProps[] = seg.segments.map((segment: TestSegmentProps) => {
  //           return {
  //             ...segment,
  //             formErrors: {
  //               fileNameError: segment.formErrors.fileNameError,
  //               isCreated: true,
  //             },
  //             editable: false,
  //             color: '#48221F',
  //           };
  //         });
  //         setSegments(update);
  //       }
  //     });
  //   }
  // };

  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //
  //              Playhead seeks to the stat time of the clip
  //              or end of the clip depending on wheher the
  //              sart time or end time are clicked
  //
  //
  const handlePlayheadSeek = (id: string | undefined, seekStart?: boolean): void => {
    //find selected segment and move playhead to that segments start time or end time
    const selectedSegment = segments.find((seg) => seg.id == id);

    seekStart
      ? myPeaks.player.seek(selectedSegment!.startTime)
      : myPeaks.player.seek(selectedSegment!.endTime);
    if (selectedSegment?.editable === false) {
      const editableSegments = segments.map((seg) => {
        if (seg.id === id) {
          return {
            ...seg,
            formErrors: {
              fileNameError: seg.formErrors.fileNameError,
              isCreated: false,
            },
            editable: true,
            color: '#1E1541',
            status: 'Staging',
          };
        } else {
          return seg;
        }
      });
      setCurrSegment && setCurrSegment(selectedSegment);
      setSegments(editableSegments);
    }
  };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             Edit segment start points
  //
  //             Handles a clip start point being dragged
  //             to a new position on the zoomview window
  //
  //
  const editClipStartPoint: any = (evt: any) => {
    //id for the current clip being edited
    const segmentId: string = evt.segment.id;

    const newSegState = segments.map((segment, idx, arr) => {
      if (segment.id == segmentId) {
        return {
          ...segment,
          startTime: evt.segment.startTime,
          editable: false,
        };
      } else {
        return segment;
      }
    });
    //use the updated segment to update the segments state
    const updated = newSegState.filter((seg) => seg.id == segmentId);

    setCurrSegment && setCurrSegment(undefined);
    setSegments(newSegState);
  };
  // const editClipStartPoint: any = (evt: any) => {
  //   //id for the current clip being edited
  //   const segmentId = evt.id;

  //   const newSegState = segments.map((segment, idx, arr) => {
  //     if (segment.id === segmentId) {
  //       //error checking for Top clip else all other clips
  //       if (idx === 0) {
  //         return {
  //           ...segment,
  //           startTime: evt.startTime > segment.endTime - 0.05 ? segment.startTime : evt.startTime,
  //         };
  //       } else {
  //         return {
  //           ...segment,
  //           startTime:
  //             evt.startTime < arr[idx - 1].endTime || evt.startTime > segment.endTime - 0.05
  //               ? segment.startTime
  //               : evt.startTime,
  //         };
  //       }
  //     }
  //     // otherwise return the segment unchanged
  //     return segment;
  //   });
  //   //use the updated segment to update the segments state
  //   const updated = newSegState.filter((seg) => seg.id == segmentId);

  //   setCurrSegment && updated.length > 0 && setCurrSegment(updated[0]);
  //   setSegments(newSegState);
  // };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             Edit segment end points
  //
  //             Handles a clip end point being dragged
  //             to a new position on the zoomview window
  //
  //

  const editClipEndPoint = (evt: any): void => {
    //id for the current clip being edited
    const segmentId: string = evt.segment.id;
    const newSegState = segments.map((segment, idx, arr) => {
      if (segment.id == segmentId) {
        return {
          ...segment,
          endTime: evt.segment.endTime,
          editable: false,
        };
      } else {
        return segment;
      }
    });
    //use the updated segment to update the segments state
    const updated = newSegState.filter((seg) => seg.id == segmentId);

    setCurrSegment && setCurrSegment(undefined);

    setSegments(newSegState);
  };
  // const editClipEndPoint = (evt: any): void => {
  //   //id for the current clip being edited
  //   const segmentId = evt.segment.id;
  //   console.log({ evt });
  //   const newSegState = segments.map((segment, idx, arr) => {
  //     if (segment.id == segmentId) {
  //       //error checking for Tail clip else all other clips
  //       if (idx === segments.length - 1) {
  //         return {
  //           ...segment,
  //           endTime:
  //             evt.segment.endTime < segment.startTime + 0.05
  //               ? segment.endTime
  //               : evt.segment.endTime,
  //         };
  //       } else {
  //         return {
  //           ...segment,
  //           endTime:
  //             evt.segment.endTime > arr[idx + 1].startTime ||
  //             evt.segment.endTime < segment.startTime + 0.05
  //               ? segment.endTime
  //               : evt.segment.endTime,
  //         };
  //       }
  //     }
  //     // otherwise return the segment unchanged
  //     return segment;
  //   });
  //   //use the updated segment to update the segments state
  //   const updated = newSegState.filter((seg) => seg.id == segmentId);

  //   setCurrSegment && updated.length > 0 && setCurrSegment(updated[0]);

  //   setSegments(newSegState);
  // };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             Add Segment Button
  //
  //             Handles a adding a new segment when the Add Segment
  //             button is clicked and the zoomview or overview timelines are double clicked
  //
  //

  const checkExisting = (fileName: string): string | undefined => {
    const segNumber = parseInt(fileName.split('-')[1]);
    if (segments.length > 0) {
      const temp = segments.filter((seg) => seg.fileName === fileName);
      if (temp.length > 0) {
        const newName = `Segment-${segNumber + 1}`;
        return checkExisting(newName);
      } else {
        return fileName;
      }
    }
  };

  const handleAddSegment = (
    onOpen: () => void,
    // setClipOverlap: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    if (userState && (userState.trialDays > 0 || state.length > 0)) {
      const firstClip = segments.length === 0;
      // const secondClip = segments.length === 1;
      let mediaLength = myPeaks.player.getDuration()!;
      const playheadPosition = myPeaks.player.getCurrentTime();
      const clipUpperBound = playheadPosition + mediaLength * 0.03;
      const timelineUpperBound = mediaLength - 1;

      if (mediaLength === Infinity) {
        mediaLength = duration;
      }

      //function to return true if the timecode is between the start and end of a clip
      // const timecodeIsBetweenClip = (timecode: number, start: number, end: number): boolean => {
      //   return (timecode - start) * (timecode - end) <= 0;
      // };

      //map over the segment and call playheadIsBetween
      //if any element is true, the playhead is between the start and end of an existing clip
      // const invalidPlayheadPosition = segments
      //   .map((seg) => {
      //     return timecodeIsBetweenClip(playheadPosition, seg.startTime, seg.endTime);
      //   })
      //   .includes(true);

      // Check the gaps between first and last clips to see if they are
      //large enough to contain the new clip length
      // eslint-disable-next-line
      // const validGapLength = segments.findIndex((seg, idx, arr) => {
      //   if (idx + 1 < arr.length) {
      //     return arr[idx + 1].startTime > clipUpperBound && arr[idx].endTime < playheadPosition;
      //   }
      // });

      //function to return true if a clip is being created with enough space, prior to the current first clip
      //this needed to be in a function to allow conditional checking of the segments[0] element
      // const startClipValidGapLength = (): boolean => {
      //   return segments.length > 0 ? clipUpperBound < segments[0]?.startTime : false;
      // };

      //function to return true if a clip is being created with enough space, after to the current last clip
      //this needed to be in a function to allow conditional checking of the segments[0] element
      // const endClipValidGapLength = (): boolean => {
      //   return segments.length > 0
      //     ? clipUpperBound < timelineUpperBound &&
      //         playheadPosition > segments[segments.length - 1].startTime
      //     : false;
      // };

      //create first clip on empty timeline
      if (firstClip && clipUpperBound < timelineUpperBound) {
        const newSegment = createNewSegment(segments, playheadPosition, mediaLength);
        const name = checkExisting(newSegment.fileName);
        //update the segments state
        setCurrSegment && setCurrSegment(newSegment);
        setSegments([newSegment]);
      }
      // } else if (
      //   //Second Clip being created only if playhead is not between start and end of first clip or upperbound
      //   //doesnt fall within clip 1
      //   secondClip &&
      //   clipUpperBound < timelineUpperBound &&
      //   !invalidPlayheadPosition &&
      //   !timecodeIsBetweenClip(clipUpperBound, segments[0].startTime, segments[0].endTime)
      // ) {
      //   const newSegment = createNewSegment(segments, playheadPosition, mediaLength);
      //   setCurrSegment && setCurrSegment(newSegment);
      //   const newSegs = segments.map((seg) => {
      //     return {
      //       ...seg,
      //       editable: false,
      //       formErrors: {
      //         ...seg.formErrors,
      //         isCreated: true,
      //       },
      //     };
      //   });
      //   const updatedSegments = [...newSegs, newSegment];
      //   //update the segments state
      //   setSegments(updatedSegments.sort((a, b) => a.startTime - b.startTime));

      //   //create clips from number 3 and up if playhead is not between start and end of first clip or upperbound
      //   //doesnt fall within existing clips
      // } else if (!invalidPlayheadPosition && validGapLength !== -1) {
      //   const newSegment = createNewSegment(segments, playheadPosition, mediaLength);
      //   setCurrSegment && setCurrSegment(newSegment);

      //   //  add new segment to the segments array, sort it by start time and update segments state
      //   const newSegs = segments.map((seg) => {
      //     return {
      //       ...seg,
      //       editable: false,
      //       formErrors: {
      //         ...seg.formErrors,
      //         isCreated: true,
      //       },
      //     };
      //   });
      //   const updatedSegments = [...newSegs, newSegment];

      //   setSegments(updatedSegments.sort((a, b) => a.startTime - b.startTime));

      //   //last conditional is for clips created before or after existing first/last clip
      //   //validGapLength will always return -1 for clips added in these positions
      //   //startClipValidGapLength and endClipValidGapLength calls will be true if valid location
      // } else if (!invalidPlayheadPosition && validGapLength === -1) {
      else {
        const newSegment = createNewSegment(segments, playheadPosition, mediaLength);
        const name = checkExisting(newSegment.fileName);
        let editiedSeg: TestSegmentProps = newSegment;
        if (name !== undefined) {
          editiedSeg = {
            ...newSegment,
            fileName: name ?? '',
          };
          //update the segments state
          setCurrSegment && setCurrSegment(editiedSeg);
        }

        //  add new segment to the segments array, sort it by start time and update segments state
        const newSegs = segments.map((seg) => {
          return {
            ...seg,
            editable: false,
            formErrors: {
              ...seg.formErrors,
              isCreated: true,
            },
          };
        });
        const updatedSegments = [...newSegs, editiedSeg];

        setSegments(updatedSegments.sort((a, b) => a.startTime - b.startTime));
      }
      // else {
      //   invalidPlayheadPosition ? setClipOverlap(false) : setClipOverlap(true);
      //   onOpen();
      // }
    }
  };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             Delete all segments
  //
  //
  const deleteAllSegments = (
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void => {
    setSegments(segments.filter((seg) => seg.status !== 'Staging'));
  };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             Create all segments
  //
  //
  // console logs the segments after all edits
  //set segments to empty array and destroy peaks instance to free resources
  const createAllSegments = (): void => {
    const updatedSegments = segments.map((seg) => {
      return {
        ...seg,
        formErrors: {
          fileNameError: seg.formErrors.fileNameError,
          isCreated: true,
        },
        editable: false,
        color: '#48221F',
      };
    });

    setSegments(updatedSegments);

    setTimeout(() => {
      setSegments([]);
      myPeaks.destroy();
    }, 5000);
  };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             Delete a single segment
  //
  //
  const deleteSingleSegment = (
    id: string,
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void => {
    //search for the segment to delete based on id passed into function
    const segmentToDelete = segments.find((seg) => {
      return seg.id === id;
    });

    //filter segments to create a new array with all segments that dont match id
    const updatedSegments = segments.filter((seg) => {
      return seg.id !== segmentToDelete!.id;
    });

    //update the date of segments with the new array
    callback && callback(params.projectId, updatedSegments);
    setSegments(updatedSegments);
  };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             Create a single segment
  //
  //
  const createSingleSegment = (
    id: string,
    callback?: (projectId: string | undefined, segs: TestSegmentProps[]) => void,
  ): void => {
    const newSegState = segments.map((seg, idx: number) => {
      if (seg.id === id) {
        return {
          ...seg,
          formErrors: {
            fileNameError: seg.formErrors.fileNameError,
            isCreated: seg.formErrors.isCreated ? false : true,
          },
          editable: seg.editable ? false : true,
          color: seg.color === '#1E1541' ? '#48221F' : '#1E1541',
          status: 'Processing',
        };
      }
      //otherwise return the segment unchanged
      return seg;
    });

    //use the updated segment to update the segments state
    callback && callback(params.projectId, newSegState);
    setSegments(newSegState);

    //find update clip that was just created in segments array and log
    const createdClip = newSegState.find((segment) => segment.id === id);
    //logging the clip

    setCurrSegment && setCurrSegment(undefined);
  };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  //
  //             handles file name error checking
  //
  //             returns true if the clips filename is empty
  //             this boolean is used by chakra-UI <FormControl>
  //             to display an error message and red highlight the input field
  //
  //
  const handleFileNameChange = (
    id: string,
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    let updateSeg: TestSegmentProps | undefined = undefined;
    //used for two way bind of filename input element to correct segment in segments
    //also used to track the the error boolean for the file name input
    const newSegState = segments.map((seg) => {
      //if the current segment id matches the id passed from the segment then bind the new value entered to the segment object
      //assign the fileNameError to true if the file name input is empty
      if (seg.id === id) {
        updateSeg = {
          ...seg,
          fileName: evt.target.value,
          labelText: evt.target.value,
          formErrors: {
            fileNameError: evt.target.value === '' ? true : false,
            isCreated: false,
          },
        };
        return {
          ...seg,
          fileName: evt.target.value,
          labelText: evt.target.value,
          formErrors: {
            fileNameError: evt.target.value === '' ? true : false,
            isCreated: false,
          },
        };
      }
      //otherwise return the segment unchanged
      return seg;
    });
    setCurrSegment && setCurrSegment(updateSeg);
    //use the updated segment to update the segments state
    setSegments(newSegState);
  };
  //////////////////////////////////////////////////////////////////////

  return {
    createGenericTopTail,
    editClipStartPoint,
    editClipEndPoint,
    handleAddSegment,
    deleteAllSegments,
    createAllSegments,
    handlePlayheadSeek,
    deleteSingleSegment,
    createSingleSegment,
    handleFileNameChange,
    clipOverlap,
    handlePersistSegments,
    loadSegments,
  };
};
