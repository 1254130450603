import { FC, ReactNode } from 'react';
import styles from './CopyToClipboard.module.scss';

import copyIcon from '../../assets/images/copy.svg';

interface CopyToClipboardProps {
  children: ReactNode;
  text: string;
  enabled: boolean;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({
  text,
  children,
  enabled,
}: CopyToClipboardProps) => {
  return (
    <>
      {children}
      {!enabled && (
        <img
          className={styles.copy}
          src={copyIcon}
          alt="Copy"
          onClick={() => navigator.clipboard.writeText(text)}
        />
      )}
    </>
  );
};

export default CopyToClipboard;
