import { FC, useState, useEffect, FormEventHandler, useContext } from 'react';
import styles from './CheckoutModal.module.scss';
// import './Checkout.scss';
import Modal from '@mui/material/Modal';
import { Typography, Grid } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PrimaryButton from '@/components/common/PrimaryButton';
import {
  useCreateSubscription,
  usePaymentMethod,
  useUpdateSubscription,
} from '@/adapters/subscription';
import { useAuth0 } from '@auth0/auth0-react';
// import { SUBSCRIPTION_PLANS_URL } from '@/routePaths';
import { UserContext } from '@/contexts/UserContext';
import { GetPaymentMethodResponse, UserSubscriptionState } from '@/entities/subscription';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

/* istanbul ignore file */
{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}
interface Props {
  open: boolean;
  isAnnualModeInit: boolean;
  handleClose(): void;
  setIsAnnualMode(value: boolean): void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  minWidth: 'auto',
  maxWidth: '1200px',
  maxHeight: '95vh',
  overflow: 'auto',
  bgcolor: 'var(--white)',
  borderRadius: '34px',
  '@media (max-width: 600px)': {
    position: 'absolute',
    bottom: '0',
    top: '55%',
    height: '90vh',
    width: '100%',
    borderRadius: '0',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
};

const CheckoutForm: FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          window.location.protocol + '//' + window.location.host + '/subscription/history'
        }`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      console.error(error.message ?? '');
    } else {
      console.error('An unexpected error occurred.');
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <PrimaryButton className={styles.submitButton} type="submit">
        <Typography className={styles.submitButtonLabel}>Purchase</Typography>
      </PrimaryButton>
    </form>
  );
};

const CheckoutModal: FC<Props> = ({ open, isAnnualModeInit, handleClose, setIsAnnualMode }) => {
  // const [isAnnualMode, setIsAnnualMode] = useState<boolean>(isAnnualModeInit);
  const [isFaqExpand, setIsFaqExpand] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');
  const { userState } = useContext(UserContext);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');
  const navigate = useNavigate();
  const { user } = useAuth0();
  const email = user?.email;
  const { mutateAsync: createSubscription, error } = useCreateSubscription();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [upgrade, setUpgrade] = useState<boolean>(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);
  const [streamKeyState, setStreamKeyState] = useState<UserSubscriptionState[] | null>(null);
  const { data: paymentMethodResponse }: { data: GetPaymentMethodResponse | undefined } =
    usePaymentMethod();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');

  const handlePaymentMethodChange = (event: SelectChangeEvent): void => {
    setSelectedPaymentMethod(event.target.value);
  };

  useEffect(() => {
    if (userState) {
      setStreamKeyState(
        userState.SubscriptionState.length > 0
          ? userState.SubscriptionState.filter(
              (state) =>
                state.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH ||
                state.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL,
            )
          : [],
      );
    }
  }, [userState]);

  const calculateNextBillingDate = (): string => {
    if (Array.isArray(streamKeyState)) {
      if (streamKeyState[0]?.next_billing_date !== 0) {
        // Use the existing next_billing_date if it's not 0
        return new Date(streamKeyState[0]?.next_billing_date * 1000).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      } else {
        // Calculate next_billing_date based on the chosen billing mode
        const isAnnualMode = isAnnualModeInit; // Assuming isAnnualModeInit is a boolean representing the billing mode

        // Calculate the next_billing_date based on the chosen billing mode
        const now = new Date();
        const nextBillingDate = isAnnualMode
          ? new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()) // Add one year for annual billing
          : new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()); // Add one month for monthly billing

        return (
          ' ' +
          nextBillingDate.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
        );
      }
    }
    return '';
  };

  useEffect(() => {
    if (email && open && streamKeyState !== null) {
      // Create PaymentIntent as soon as the page loads
      if (streamKeyState && streamKeyState.length === 0) {
        const createConfig: () => Promise<void> = async () => {
          setLoading(true);
          const { clientSecret }: { clientSecret: string } = await createSubscription({
            email: email,
            lookup_key:
              (isAnnualModeInit
                ? process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL
                : process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH) || '',
          });

          setLoading(false);
          setClientSecret(clientSecret);
        };

        createConfig()
          .then(() => {
            console.log('success');
          })
          .catch(() => {
            console.log('failed');
          });
      } else if (
        Array.isArray(streamKeyState) &&
        streamKeyState[0]?.state &&
        streamKeyState[0].plan === 'eos-pro-monthly' &&
        !isAnnualModeInit
      ) {
        setAlreadySubscribed(true);
        setUpgrade(false);
      } else if (
        Array.isArray(streamKeyState) &&
        streamKeyState[0]?.state &&
        streamKeyState[0]?.plan === 'eos-pro-annually'
      ) {
        setAlreadySubscribed(true);
        setUpgrade(false);
      } else {
        setAlreadySubscribed(false);
        setIsAnnualMode(true);
        setUpgrade(true);
      }
    }
  }, [email, isAnnualModeInit, createSubscription, open, userState, streamKeyState]);

  const { mutateAsync: updateSubscription } = useUpdateSubscription();
  const handleUpdateSubscription = async (): Promise<void> => {
    if (email) {
      setIsLoading(true);
      const { updated }: { updated: string } = await updateSubscription({
        email: email,
        lookup_key: 'eos-pro-annually',
        paymentMethodId: selectedPaymentMethod,
      });

      if (updated) {
        // window.location.reload();
        navigate('/subscription/history');
      }
    }
  };

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose} disableScrollLock>
      <Box sx={{ ...style, minWidth: 'auto', maxHeight: '95vh', overflow: 'auto' }}>
        <div className={styles.closeMark} onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </div>
        <Grid container className={styles.gridContainer}>
          <Grid item xs={12} md={6} className={styles.orderSummary}>
            <Typography className={styles.planTitle}>PRO</Typography>
            <Typography className={styles.planDescription}>Keep all your videos forever</Typography>
            <Typography className={styles.orderTitle}>Order Summary</Typography>
            <div className={styles.paymentModeSwitch}>
              <div className={styles.paymentModes}>
                <div
                  className={styles.paymentMode + (isAnnualModeInit ? '' : ' ' + styles.activeMode)}
                  onClick={() => setIsAnnualMode(false)}
                >
                  Monthly
                </div>
                <div
                  className={styles.paymentMode + (isAnnualModeInit ? ' ' + styles.activeMode : '')}
                  onClick={() => setIsAnnualMode(true)}
                >
                  Annually
                </div>
              </div>
              <div className={styles.annualDiscount}>Save 20%</div>
            </div>
            <div className={styles.invoice}>
              <div className={styles.priceTable}>
                <div className={styles.tableHeader}>
                  <Typography className={styles.tableItem}>Product/Service</Typography>
                  <Typography className={styles.tableItem}>Price</Typography>
                </div>
                <div className={styles.tableBody}>
                  <div className={styles.productDescription}>
                    <div className={styles.closeIcon}>
                      <CloseIcon />
                    </div>
                    <div className={styles.productContent}>
                      <Typography className={styles.productTop}>Pro Plan</Typography>
                      <Typography className={styles.productBottom}>
                        EditOnTheSpot Pro Package
                      </Typography>
                    </div>
                  </div>
                  <Typography className={styles.price}>
                    {isAnnualModeInit ? '$480' : '$49.95'}
                  </Typography>
                </div>
                <div className={styles.horizonLine}></div>
              </div>
              <div className={styles.totalPrice}>
                {streamKeyState && !streamKeyState[0]?.adjustments && (
                  <div className={styles.totalPriceTop}>
                    <Typography className={styles.tableItem}>Total:</Typography>
                    <Typography className={styles.tableItem}>
                      {isAnnualModeInit ? '$480' : '$49.95'}
                    </Typography>
                  </div>
                )}
                {Array.isArray(streamKeyState) &&
                  streamKeyState[0]?.adjustments &&
                  isAnnualModeInit && (
                    <>
                      <div className={styles.totalPriceTop}>
                        <Typography className={styles.renewInfo}>Used Amount:</Typography>
                        <Typography className={styles.renewInfo}>
                          {'$' + streamKeyState[0]?.adjustments.usedAmount + ' USD'}
                        </Typography>
                      </div>
                      <div className={styles.totalPriceTop}>
                        <Typography className={styles.renewInfo}>Prorated Amount:</Typography>
                        <Typography className={styles.renewInfo}>
                          {'$' + streamKeyState[0]?.adjustments.proratedAmount + ' USD'}
                        </Typography>
                      </div>
                      <div className={styles.totalPriceTop}>
                        <Typography className={styles.tableItem}>Total for Upgrade:</Typography>
                        <Typography className={styles.tableItem}>
                          {'$' + streamKeyState[0]?.adjustments.upgradeTotal + ' USD'}
                        </Typography>
                      </div>
                    </>
                  )}
                <div className={styles.totalPriceBottom}>
                  <Typography className={styles.renewInfo}>
                    Subscription renews on
                    <span className={styles.renewDate}>{' ' + calculateNextBillingDate()}</span>
                  </Typography>
                  <Typography className={styles.totalPriceUnit}>USD</Typography>
                </div>
                <div className={styles.horizonLine}></div>
              </div>
              <div className={styles.email}>
                <Typography className={styles.inputLabel}>Email Address</Typography>
                <input className={styles.emailInput} placeholder={email} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={styles.paymentDetails}>
            <Typography className={styles.paymentDetailTitle}>Payment Details</Typography>
            <div className={styles.paymentElement}>
              {!loading && clientSecret && !upgrade && (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: clientSecret,
                    // mode: 'payment',
                    // currency: 'usd',
                    // amount: 1099,
                    appearance: {
                      variables: {
                        fontSizeBase: '13px',
                        borderRadius: '6px',
                        fontWeightNormal: '500',
                        colorText: '#2C3277',
                        colorTextPlaceholder: '#A7A7A7',
                      },
                      rules: {
                        '.Input': {
                          border: '2px #000 solid',
                          boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.07)',
                        },
                      },
                    },
                  }}
                >
                  <CheckoutForm />
                </Elements>
              )}
              {upgrade && (
                <>
                  <Typography className={styles.paymentList}>
                    Payment Method List
                    {/* <span className={styles.renewDate}>{calculateNextBillingDate()}</span> */}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Payment Method"
                    style={{ width: '100%', flex: 1, display: 'flex' }}
                    value={
                      selectedPaymentMethod.length > 0
                        ? selectedPaymentMethod
                        : 'Please select payment method'
                    }
                    onChange={handlePaymentMethodChange}
                    sx={{ height: '40px', borderRadius: '8px' }}
                    // onChange={handleChange}
                  >
                    <MenuItem key={'index'} value={'Please select payment method'} disabled>
                      Please select payment method
                    </MenuItem>
                    {paymentMethodResponse &&
                      paymentMethodResponse.payment_methods.length > 0 &&
                      paymentMethodResponse.payment_methods.map((card, index) => {
                        return (
                          <MenuItem key={index} value={card.id}>
                            {'xxxx xxxx xxxx ' + card.card.last4}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </>
              )}
              {upgrade && (
                <PrimaryButton
                  className={styles.submitButton}
                  type="submit"
                  disabled={!selectedPaymentMethod || isLoading}
                  onClick={async () => await handleUpdateSubscription()}
                >
                  <Typography className={styles.submitButtonLabel}>
                    {!isLoading ? 'Upgrade to Annually' : 'Processing'}
                  </Typography>
                </PrimaryButton>
              )}
            </div>
            {(error || alreadySubscribed) && (
              <div className={styles.faqLabel} style={{ color: 'red', textAlign: 'center' }}>
                Already Subscribed
              </div>
            )}

            <div className={styles.faq}>
              <div className={styles.faqLabel} onClick={() => setIsFaqExpand(!isFaqExpand)}>
                Frequently Asked Questions
                {isFaqExpand ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
              </div>
              {isFaqExpand && (
                <div className={styles.faqContent}>
                  <Typography className={styles.faqQuestion}>
                    How is pricing calculated for the Business plan?
                  </Typography>
                  <Typography className={styles.faqAnswer}>
                    Pricing is calculated per subscription. Pay annually $480 USD per year (yearly
                    savings). Pay monthly is $49.95 USD per month.
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CheckoutModal;
