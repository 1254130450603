/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import React, { useContext } from 'react';
import styles from './Sidebar.module.scss';
import logoImage from '../../assets/images/eos-logo-red.svg';
import logoOnly from '../../assets/images/logo192.png';
import brandKitIcon from '../../assets/images/menu-brand-kit.svg';
import streamKeyIcon from '../../assets/images/menu-stream-key.svg';
import projectLibraryIcon from '../../assets/images/menu-project-library.svg';
import tutorialsIcon from '../../assets/images/menu-tutorial.svg';
import integrationsIcon from '../../assets/images/Integrations.svg';
import orderHistoryIcon from '../../assets/images/menu-order-history.svg';
import menuCloseIcon from '../../assets/images/menu_open.svg';
import menuOpenIcon from '../../assets/images/bars-solid.svg';
import { Link } from 'react-router-dom';
import {
  PROJECTS_URL,
  BRAND_KIT_URL,
  STREAM_KEYS_URL,
  SUBSCRIPTION_URL,
  SUBSCRIPTION_HISTORY_URL,
  INTEGRATIONS_URL,
  TUTORIALS_URL,
  USERS_URL,
} from '../../routePaths';
import { NavLink, useLocation } from 'react-router-dom';
import LogoutIcon from '../Icons/LogoutIcon';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthorization from '@/hooks/useAuthorization';
import { UserContext } from '@/contexts/UserContext';
import { EnterpriseMap } from '@/pages/Users/Users';

interface SidebarProps {
  toggleSidebar(): void;
  isOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ toggleSidebar, isOpen }) => {
  const { logout, user } = useAuth0();
  const { userState } = useContext(UserContext);
  const { hasRole } = useAuthorization();
  const email = user?.email;

  const beforeAtSign = email?.substr(0, email.indexOf('@') + 1);
  const afterAtSign = email?.substr(email.indexOf('@') + 1);

  const location = useLocation();
  const currentUrl = location.pathname;

  const isSubscPage: boolean = currentUrl.includes(SUBSCRIPTION_URL);

  const iconKey = isOpen ? 'menuCloseIcon' : 'menuOpenIcon';

  return (
    <aside className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
      <div>
        <div className={isOpen ? styles.sidebarHeaderOpen : styles.sidebarHeaderClosed}>
          <Link to="/" className={styles.logo}>
            <img
              src={isOpen ? logoImage : logoOnly}
              alt="logo"
              className={isOpen ? styles.logoImage : styles.logoOnly}
            />
          </Link>
          <img
            key={iconKey}
            src={isOpen ? menuCloseIcon : menuOpenIcon}
            className={`${styles.menuIconButton}`}
            alt={isOpen ? 'menu open' : 'menu close'}
            onClick={toggleSidebar}
          />
        </div>
      </div>
      <div className={styles.profileInfo}>
        <div className={styles.profileInfoImg}>
          {/* <img
            src="https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png"
            alt="profile"
          /> */}
          {EnterpriseMap[`${userState?.role}`] ? EnterpriseMap[`${userState?.role}`] : 'Loading...'}
        </div>
        <div className={styles.profileInfoTexts}>
          <div className={styles.profileInfoEmail}>
            <button
              className={styles.logout}
              onClick={() =>
                logout({
                  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                  returnTo: window.location.origin,
                })
              }
            >
              <div className={isOpen ? styles.email : styles.emailClosed}>
                <p>{beforeAtSign}</p>
                <p>{afterAtSign}</p>
              </div>
              <LogoutIcon
                className={`${styles.logoutIcon} ${isOpen ? '' : styles.logoutIconClosed}`}
              />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          <NavLink to={PROJECTS_URL} className={`${styles.item} ${isOpen ? '' : styles.centered}`}>
            <img src={projectLibraryIcon} alt="project library" />
            {isOpen && <span>Projects Library</span>}
          </NavLink>
          <NavLink to={BRAND_KIT_URL} className={`${styles.item} ${isOpen ? '' : styles.centered}`}>
            <img src={brandKitIcon} alt="brand kit" />
            {isOpen && <span>Brand Kit</span>}
          </NavLink>
          <NavLink
            to={STREAM_KEYS_URL}
            className={`${styles.item} ${isOpen ? '' : styles.centered}`}
          >
            <img src={streamKeyIcon} alt="stream keys" />
            {isOpen && <span>Stream Keys</span>}
          </NavLink>
          {!hasRole(['temporary_enterprise', 'enterprise', 'super_admin']) && (
            <NavLink
              to={SUBSCRIPTION_HISTORY_URL}
              className={`${isSubscPage ? styles.item + ' active' : styles.item}
              ${isOpen ? '' : styles.centered}`}
            >
              <img src={orderHistoryIcon} alt="order history" />
              {isOpen && <span>Order History</span>}
            </NavLink>
          )}
          {!hasRole(['standard']) && (
            <NavLink
              to={INTEGRATIONS_URL}
              className={`${styles.item} ${isOpen ? '' : styles.centered}`}
            >
              <img src={integrationsIcon} alt="integrations" />
              {isOpen && <span>Integrations</span>}
            </NavLink>
          )}
          <NavLink
            to={TUTORIALS_URL}
            className={`${styles.item} ${isOpen ? '' : styles.centered}`}
            target="_blank"
          >
            <img src={tutorialsIcon} alt="Tutorials" />
            {isOpen && <span>Tutorials</span>}
          </NavLink>
          {hasRole('super_admin') && (
            <NavLink to={USERS_URL} className={`${styles.item} ${isOpen ? '' : styles.centered}`}>
              <img src={projectLibraryIcon} alt="users manage" />
              {isOpen && <span>Manage Users</span>}
            </NavLink>
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
