import { useVideoContext } from '@/components/Video/Video';
import { ProjectContext } from '@/contexts/ProjectContext';
import { VideoContext } from '@/contexts/VideoContext';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useUploadVideoToDrive } from '../../../adapters/projects';

interface DriveUploadDialogProps {
  open: boolean;
  onClose(): void;
}

const DriveUploadDialog: FC<DriveUploadDialogProps> = ({ open, onClose }) => {
  const [fileName, setFileName] = useState<string>('');
  const { project } = useContext(ProjectContext);
  const { video } = useContext(VideoContext);
  const { videoUrl } = useVideoContext();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const email = user?.email;

  const {
    data: uploadResponse,
    mutateAsync: uploadToDrive,
    isLoading: isUploading,
    reset: resetUploadState,
  } = useUploadVideoToDrive();

  useEffect(() => {
    if (!open) {
      resetUploadState();
    } else if (project && video) {
      setFileName(`Edit on the Spot - ${project.name}.mp4`);
    }
  }, [open, project, video, resetUploadState]);

  const handleUpload = useCallback(async () => {
    if (!project || !videoUrl) return;

    try {
      await uploadToDrive({
        projectId: project.id,
        email: email ?? '',
        videoUrl: videoUrl,
        type: 'drive',
      });
      enqueueSnackbar('The file has been uploaded to your drive', { variant: 'info' });
    } catch (error) {
      enqueueSnackbar('Failed to start upload', { variant: 'error' });
    }
  }, [project, uploadToDrive, enqueueSnackbar]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: 16 }}>Upload to Drive</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {!isUploading && !uploadResponse ? (
            <TextField
              variant="outlined"
              error={!fileName.trim()}
              placeholder="File name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              fullWidth
              size="small"
            />
          ) : isUploading ? (
            <CircularProgress />
          ) : (
            <Alert variant="outlined" severity="success">
              The file has been uploaded to your drive.
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {!uploadResponse && (
          <Button onClick={handleUpload} disabled={isUploading}>
            Upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DriveUploadDialog;
