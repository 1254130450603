/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */

import EditableLabel from '@//components/EditableLabel';
import Api from '@/adapters/api';
import {
  useDeleteProject,
  useDeleteSegment,
  useEditProject,
  useGetProjectList,
} from '@/adapters/projects';
import emptyPic from '@/assets/images/projects/empty.svg';
import DefaultButton from '@/components/common/DefaultButton';
import FileButton from '@/components/common/FileButton';
import PrimaryButton from '@/components/common/PrimaryButton';
import Loading from '@/components/Loading';
import { UploadContext } from '@/contexts/UploadContext';
import { UserContext } from '@/contexts/UserContext';
import { UserSubscriptionState } from '@/entities/subscription';
import { PROJECT_URL, PROJECT_VIDEO_URL } from '@/routePaths';
import Upload from '@mui/icons-material/Upload';
import { Box, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import NoProjects from './NoProjects';
import styles from './Projects.module.scss';
const connectToZoomLink = process.env.REACT_APP_ZOOM_APP_URL || '';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid var(--silver)`,
  borderRadius: '8px',
  marginBottom: '15px',
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: 'var(--gallery)',
  borderBottomColor: 'transparent',
  borderRadius: '8px',
  flexDirection: 'row-reverse',
  padding: 0,
  '&.Mui-expanded': {
    borderRadius: '8px 8px 0 0',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: '10px',
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
{
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
}
const Projects: FC = () => {
  const { data: projects = [], isLoading } = useGetProjectList();
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [highlightedVideo, setHighlightedVideo] = React.useState<string | null>(null);
  const [highlight, setHighlight] = React.useState<boolean>(false);
  const { userState, setUserState } = useContext(UserContext);
  const [editableProjects, setEditableProjects] = React.useState<number[]>([]);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const { duration, uploadProgress, uploadFile } = useContext(UploadContext);
  const [currentProjectId, setCurrentProjectId] = React.useState<string>('');
  const { mutateAsync: editProject } = useEditProject(currentProjectId);
  const [state, setState] = useState<UserSubscriptionState[]>([]);
  const editableLabelRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: deleteProject } = useDeleteProject();
  const { mutateAsync: deleteSegment } = useDeleteSegment();
  const viewVideo = (id: string, videoId: string): void => {
    // Store the project and video ids in the local storage
    localStorage.setItem('selectedProjectId', id);
    localStorage.setItem('selectedVideoId', videoId);

    if (videoId === 'original') {
      navigate(generatePath(PROJECT_URL, { projectId: id }));
    } else {
      navigate(generatePath(PROJECT_VIDEO_URL, { projectId: id, videoId: videoId }));
    }
  };
  const handleEditClick = (projectId: number): void => {
    setEditableProjects((prevEditableProjects) => {
      const newEditableProjects = [projectId];
      setCurrentProjectId(projectId.toString());

      return newEditableProjects;
    });
  };
  // const isProjectEditable = (projectId: number): boolean => {
  //   return editableProjects.length === 1 && editableProjects.includes(projectId);
  // };
  useEffect(() => {
    if ((projects as any[]).length > 0) {
      // Retrieve the project and video ids from the local storage
      const storedProjectId = localStorage.getItem('selectedProjectId');
      const storedVideoId = localStorage.getItem('selectedVideoId');

      // Expand desired accordion
      setExpanded(storedProjectId && storedVideoId ? storedProjectId : projects[0].id);
      setHighlightedVideo(storedVideoId);
      // Trigger highlight
      setHighlight(true);

      // Remove after 1 sec
      const timeoutId = setTimeout(() => {
        setHighlight(false);
      }, 2000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [projects]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (editableLabelRef.current && !editableLabelRef.current.contains(event.target as Node)) {
        // Clicked outside the div, deactivate EditableLabel
        setEditableProjects([]);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editableLabelRef, setEditableProjects]);
  useEffect(() => {
    const updateUserUploadDuration = async () => {
      if (uploadProgress === 100) {
        const durationInMilliseconds = duration && duration * 1000;
        await Api.patch(`user/update/${userState?.id}`, {
          uploadDuration: durationInMilliseconds,
        });

        enqueueSnackbar('File uploaded successfully', { variant: 'info', autoHideDuration: 5000 });
      }
    };

    updateUserUploadDuration();
  }, [uploadProgress, enqueueSnackbar]);

  useEffect(() => {
    if (userState) {
      setState(
        userState.SubscriptionState.filter(
          (sub) =>
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_ANNUAL ||
            sub.plan === process.env.REACT_APP_STRIPE_PLAN_PRO_MONTH,
        ),
      );
    }
  }, [userState]);

  return (
    <>
      <div className={styles.navbar}>
        <h1 className={styles.projectsHeadTitle}>Projects Library</h1>
        {state.length === 0 && userState && userState.trialDays === 0 ? (
          <PrimaryButton
            style={{ opacity: '0.4' }}
            onClick={() => {
              navigate('/subscription/plans');
            }}
          >
            <div
              className={styles.buttonLabel + ' ' + styles.backButtonLabel}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Upload <Upload fontSize="small" />
            </div>
          </PrimaryButton>
        ) : (
          <FileButton size="medium" onFileChange={uploadFile} disabled={!!uploadProgress}>
            {uploadProgress == null ? (
              <>
                Upload &nbsp; <Upload style={{ fontSize: '1.5em' }} />{' '}
              </>
            ) : (
              <>
                Uploading file &nbsp; <Typography variant="caption">{uploadProgress}%</Typography>
              </>
            )}
          </FileButton>
        )}
      </div>
      {isLoading ? (
        <Loading />
      ) : (projects as any[]).length === 0 ? (
        <NoProjects
          media={<img src={emptyPic} alt="no projects" />}
          text={
            <>
              <p>You don’t have projects yet.</p>

              <div className={styles.useDescription}>
                <p>
                  1. Copy your Stream Key in your favorite platform to start streaming to Edit on
                  the Spot
                </p>
                <p>2. Upload a video with the button on the top right corner</p>
                <p>3. Connect your Zoom Pro or Webinar account below</p>
                <p>For more information consult Tutorials</p>
              </div>
            </>
          }
          linkText={'Connect to Zoom App'}
          link={connectToZoomLink}
        />
      ) : (
        <>
          {
            <>
              <div className={styles.table}>
                <div className={styles.tableTitle}>
                  <div className={styles.tableItem}>Project Name</div>
                  <div className={styles.tableItem}>Date</div>
                  <div className={classNames(styles.tableItem, styles.hiddenSm)}>Time</div>
                  <div className={styles.tableItem}>Action</div>
                </div>
                {projects.map((project) => (
                  <Accordion
                    key={project.id}
                    expanded={expanded === project.id}
                    onChange={handleChange(project.id)}
                  >
                    <AccordionSummary>
                      <Typography
                        sx={{ flex: '5 1', padding: 0 }}
                        className={styles.tableItem + ' ' + styles.specialColumn}
                      >
                        <div
                          onClick={() => handleEditClick(Number(project.id))}
                          style={{ cursor: 'pointer' }}
                          ref={editableLabelRef}
                          data-project-id={project.id}
                        >
                          {currentProjectId === project.id.toString() ? (
                            <EditableLabel
                              key={project.id}
                              text={project.name}
                              onSave={(text) => editProject({ name: text })}
                              testId="project-name"
                              icon="off"
                            />
                          ) : (
                            project.name
                          )}
                        </div>
                      </Typography>
                      <Typography sx={{ flex: '1 1', padding: 0 }} className={styles.specialColumn}>
                        {moment(project.created).format('MMMM D, YYYY')}
                      </Typography>
                      <Typography sx={{ flex: '1 1', padding: 0 }} className={styles.specialColumn}>
                        {moment(project.created).format('hh:mm A')}
                      </Typography>
                      <Typography sx={{ flex: '1 1', padding: 0 }}></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {!project.original && (project.edits as any[]).length === 0 && (
                        <Box sx={{ display: 'flex', padding: '10px' }}>
                          <Typography sx={{ flex: '5 1', padding: 0 }}>No video found.</Typography>
                        </Box>
                      )}
                      {project.original && (
                        <Box sx={{ display: 'flex', padding: '10px' }}>
                          <Typography
                            sx={{ flex: '5 1', padding: 0, lineHeight: '32px' }}
                            className={styles.tableItem + ' ' + styles.specialColumn}
                          >
                            Original
                          </Typography>
                          <Typography
                            sx={{ flex: '1 1', padding: 0, lineHeight: '32px' }}
                            className={styles.specialColumn}
                          >
                            {moment(project.created).format('MMMM D, YYYY')}
                          </Typography>
                          <Typography
                            sx={{ flex: '1 1', padding: 0, lineHeight: '32px' }}
                            className={styles.specialColumn}
                          >
                            {moment(project.created).format('hh:mm A')}
                          </Typography>
                          <Box
                            sx={{ flex: '1 1', padding: 0, display: 'flex', gap: '5px' }}
                            className={styles.tableBtn}
                          >
                            <DefaultButton
                              size="small"
                              sx={{ paddingLeft: 1, paddingRight: 1 }}
                              onClick={() => viewVideo(project.id, 'original')}
                            >
                              View
                            </DefaultButton>
                            <DefaultButton
                              size="small"
                              sx={{ paddingLeft: 1, paddingRight: 1 }}
                              onClick={() => deleteProject(project.id)}
                            >
                              Delete
                            </DefaultButton>
                          </Box>
                        </Box>
                      )}
                      {project.edits.map((edit) => (
                        <>
                          {edit.status === 'Processed' && (
                            <Box
                              sx={{
                                display: 'flex',
                                padding: '10px',
                                ...(edit.id === highlightedVideo && highlight
                                  ? {
                                      animation: 'highlightAnimation 2s ease-out',
                                    }
                                  : {}),
                                '@keyframes highlightAnimation': {
                                  '0%': { border: '1px solid #191c43' },
                                  '100%': { border: '1px solid transparent' },
                                },
                              }}
                              key={edit.id}
                            >
                              {/* <Typography sx={{ flex: '5 1', padding: 0, lineHeight: '32px' }}>
                                {edit.name}
                              </Typography> */}
                              <Typography
                                sx={{ flex: '5 1', padding: 0, lineHeight: '32px' }}
                                className={styles.tableItem + ' ' + styles.specialColumn}
                              >
                                {edit.name}
                              </Typography>
                              <Typography
                                sx={{ flex: '1 1', padding: 0, lineHeight: '32px' }}
                                className={styles.specialColumn}
                              >
                                {moment(project.created).format('MMMM D, YYYY')}
                              </Typography>
                              <Typography
                                sx={{ flex: '1 1', padding: 0, lineHeight: '32px' }}
                                className={styles.specialColumn}
                              >
                                {moment(project.created).format('hh:mm A')}
                              </Typography>
                              <Box
                                sx={{ flex: '1 1', padding: 0, display: 'flex', gap: '5px' }}
                                className={styles.tableBtn}
                              >
                                <DefaultButton
                                  size="small"
                                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                                  onClick={() => viewVideo(project.id, edit.id)}
                                >
                                  Edit
                                </DefaultButton>
                                <DefaultButton
                                  size="small"
                                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                                  onClick={() =>
                                    deleteSegment({ id: project.id, segmentId: edit.id })
                                  }
                                >
                                  Delete
                                </DefaultButton>
                              </Box>
                            </Box>
                          )}
                        </>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
                <div className={styles.tableTitle}>
                  <div className={styles.tableItem}>Project Name</div>
                  <div className={styles.tableItem}>Date</div>
                  <div className={classNames(styles.tableItem, styles.hiddenSm)}>Time</div>
                  <div className={styles.tableItem}>Action</div>
                </div>
              </div>
            </>
          }
        </>
      )}
    </>
  );
};
export default Projects;
