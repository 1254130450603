/* eslint-disable */
import {
  useAddPlatform,
  useDisconnectIntegration,
  useGetIntegrations,
} from '@/adapters/integrations';
import DriveLogo from '@/assets/images/drive-icon.svg';
import JWPLogo from '@/assets/images/jwpLogo.svg';
import S3Logo from '@/assets/images/s3.svg';
import WowzaLogo from '@/assets/images/wowzaLogo.svg';
import YouTubeLogo from '@/assets/images/youtube-icon.svg';
import DefaultButton from '@/components/common/DefaultButton';
import PrimaryButton from '@/components/common/PrimaryButton';
import Loading from '@/components/Loading';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { FC } from 'react';
import styles from '../Integrations.module.scss';

interface AppRequirement {
  [key: string]: string;
}

interface App {
  id: string;
  logo: string;
  name: string;
  platform_type: string;
  description: string;
  requirements: AppRequirement[];
}

interface IntegratedApps {
  [key: string]: App;
}

const integratedApp: IntegratedApps = {
  app1: {
    id: 'wowza',
    logo: WowzaLogo,
    name: 'Wowza Streaming Engine',
    platform_type: 'wowza',
    description:
      'The embedded solution platform for Solution Builders. The Wowza platform offers end-to-end video solutions, for both live video and VOD streaming.',
    requirements: [
      {
        token: '',
      },
    ],
  },
  app2: {
    id: 'jwp',
    logo: JWPLogo,
    name: 'JW Player',
    platform_type: 'jwp',
    description:
      'Video Platform for Video-Driven Companies. Easily stream video at any scale, expand & engage your audiences on any screen, and monetize your content.',
    requirements: [
      {
        api_key: '',
        site_id: '',
      },
    ],
  },
  app3: {
    id: 's3',
    logo: S3Logo,
    name: 'AWS S3',
    platform_type: 's3',
    description:
      'Video Platform for Video-Driven Companies. Easily stream video at any scale, expand & engage your audiences on any screen, and monetize your content.',
    requirements: [
      {
        access_key_id: '',
        secret_access_key: '',
        s3_bucket_name: '',
        s3_region: '',
      },
    ],
  },
  app4: {
    id: 'youtube',
    logo: YouTubeLogo,
    name: 'YouTube',
    platform_type: 'youtube',
    description:
      'Video Platform for Video-Driven Companies. Easily stream video at any scale, expand & engage your audiences on any screen, and monetize your content.',
    requirements: [],
  },
  app5: {
    id: 'drive',
    logo: DriveLogo,
    name: 'Google Drive',
    platform_type: 'drive',
    description:
      'Video Platform for Video-Driven Companies. Easily stream video at any scale, expand & engage your audiences on any screen, and monetize your content.',
    requirements: [],
  },
};

const CloudStorage: FC = () => {
  const { data: integrations = [], isLoading, refetch } = useGetIntegrations();
  const { mutateAsync: addPlatform, isLoading: isLoadingAddPlatform } = useAddPlatform();
  const { mutateAsync: deleteIntegration } = useDisconnectIntegration();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [connected, setConnected] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<any>(null);
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [empty, setIsEmpty] = React.useState<boolean>(false);
  const [currentType, setCurrentType] = React.useState<string>('youtube');

  const handleExpansion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (panel === 'youtube' || panel === 'drive') {
      setCurrentType(panel);
      setExpanded(false);
    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    setInputValue({ ...inputValue, [key]: e.target.value });
  };

  const handleConnectWowza = async (type: string, data?: any): Promise<void> => {
    try {
      await addPlatform({
        platformType: type,
        payload: data ? data : inputValue,
      });

      setIsSuccess(true);
      setConnected(true);
      await refetch();
    } catch (error) {
      console.error('Error connecting to Wowza:', error);
      setIsSuccess(false);
    }
  };

  const handleDisconnectWowza = async (id: string): Promise<void> => {
    try {
      await deleteIntegration(id);
      await refetch();
      setIsSuccess(false);
    } catch (error) {
      console.error('Error disconnecting from integration:', error);
      setIsSuccess(false);
    }
  };

  const googleHandler = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await axios.post('https://oauth2.googleapis.com/token', {
          code: codeResponse.code,
          client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_SECRET,
          redirect_uri: process.env.REACT_APP_URL,
          grant_type: 'authorization_code',
        });
        const { access_token } = response.data;

        if (access_token) {
          await handleConnectWowza(currentType, response.data);
        }
      } catch (error) {
        console.error('Error exchanging code for access token:', error);
      }
    },
    flow: 'auth-code',
    scope:
      'openid email profile https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/drive.file',
    redirect_uri: process.env.REACT_APP_URL,
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.cloudStorage}>
          {Object.entries(integratedApp).map(([key, app]) => (
            <div key={key}>
              <Accordion
                key={app.id}
                expanded={expanded === app.id}
                onChange={handleExpansion(app.id)}
                className={styles.accordion}
              >
                <AccordionSummary>
                  <div className={styles.container}>
                    <div className={styles.summary}>
                      <img src={app.logo} alt={`${app.name} Logo`} />
                      <Typography fontWeight={'bold'} className={styles.summaryTitle}>
                        {app.name}
                      </Typography>
                    </div>
                    <Typography className={styles.summaryDescription}>{app.description}</Typography>
                    {expanded === app.id ? null : (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {integrations.some((integration: any) => integration.platform[app.id]) ? (
                          <DefaultButton
                            className={styles.summaryButton}
                            onClick={() => handleDisconnectWowza(app.id)}
                          >
                            Disconnect
                          </DefaultButton>
                        ) : (
                          <PrimaryButton
                            className={styles.summaryButton}
                            disabled={isSuccess}
                            onClick={() =>
                              (app.id === 'youtube' || app.id === 'drive') && googleHandler()
                            }
                          >
                            Connect
                          </PrimaryButton>
                        )}
                      </div>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.details}>
                    {app.requirements.map((requirement, reqIndex) => (
                      <div key={reqIndex}>
                        {Object.entries(requirement).map(([key, value], pairIndex) => (
                          <TextField
                            key={pairIndex}
                            label={key}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              handleInputChange(event, key)
                            }
                            id={`outlined-basic-${reqIndex}-${pairIndex}`}
                            size="small"
                            sx={{ paddingBottom: '15px' }}
                          />
                        ))}
                      </div>
                    ))}
                    {!(app.id === 'youtube' || app.id === 'drive') && (
                      <div
                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}
                      >
                        {isLoadingAddPlatform ? (
                          <CircularProgress size={20} sx={{ marginRight: 1 }} />
                        ) : (
                          <PrimaryButton
                            className={styles.summaryButton}
                            onClick={() => handleConnectWowza(app.id)}
                            disabled={isSuccess}
                          >
                            Connect
                          </PrimaryButton>
                        )}
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CloudStorage;
