import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import EditProject from '@/types//edit-project';
import ProjectListItem from '@/types//project-list-item';
import Project, {
  ProjectUploadResponse,
  UpdateProjectVideoTranscript,
  UploadVideoToWowzaResponse,
} from '@/types//project';
import Api from './api';
import ProjectShareUrls from '@/types/project-share-urls';
import { Transcript } from '@/types/transcript';
import DeleteSegment from '@/types/delete-segment';

/* istanbul ignore file */

export const LIST_PROJECTS_CACHE_KEY = 'list-projects';
export const MAKE_GET_PROJECT_CACHE_KEY = (id: string): string => `get-project-${id}`;
export const MAKE_GET_PROJECT_VIDEO_TRANSCRIPT_CACHE_KEY = (id: string, videoId: string): string =>
  `get-project-video-transcript-${id}-${videoId}`;
export const MAKE_GET_PROJECT_SHARE_URLS_CACHE_KEY = (id: string): string =>
  `get-project-share-urls-${id}`;
export const GET_UPLOAD_URL_CACHE_KEY = 'get-upload-url';

export function useGetProjectList(): UseQueryResult<ProjectListItem[]> {
  return useQuery(LIST_PROJECTS_CACHE_KEY, async () => {
    return await Api.get('/projects');
  });
}

export function useGetProject(id: string): UseQueryResult<Project> {
  return useQuery(
    MAKE_GET_PROJECT_CACHE_KEY(id),
    async () => {
      return await Api.get(`/projects/${id}`);
    },
    {
      enabled: !!id,
    },
  );
}

export function useGetProjectVideoTranscript(
  id: string,
  videoId: string,
): UseQueryResult<Transcript> {
  return useQuery(
    MAKE_GET_PROJECT_VIDEO_TRANSCRIPT_CACHE_KEY(id, videoId),
    async () => {
      return await Api.get(`/projects/${id}/videos/${videoId}/transcript`);
    },
    {
      enabled: !!id && !!videoId,
    },
  );
}

export function useUpdateProjectVideoTranscript(
  id: string,
  videoId: string,
): UseMutationResult<void, unknown, UpdateProjectVideoTranscript> {
  const queryClient = useQueryClient();

  return useMutation(
    async (updateProjectVideoTranscript: UpdateProjectVideoTranscript) => {
      await Api.patch(`/projects/${id}/videos/${videoId}/transcript`, updateProjectVideoTranscript);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries([LIST_PROJECTS_CACHE_KEY, MAKE_GET_PROJECT_CACHE_KEY(id)]),
    },
  );
}

export function useGetProjectShareUrls(id: string): UseQueryResult<ProjectShareUrls> {
  return useQuery(
    MAKE_GET_PROJECT_SHARE_URLS_CACHE_KEY(id),
    async () => {
      return await Api.get(`/projects/${id}/share-urls`);
    },
    { enabled: !!id },
  );
}

export function useEditProject(id: string): UseMutationResult<void, unknown, EditProject> {
  const queryClient = useQueryClient();

  return useMutation(
    async (editProject: EditProject) => {
      await Api.patch(`/projects/${id}`, editProject);
    },
    {
      onSuccess: async () => {
        queryClient.removeQueries(MAKE_GET_PROJECT_CACHE_KEY(id), { exact: true });
        await queryClient.invalidateQueries(LIST_PROJECTS_CACHE_KEY);
      },
    },
  );
}

export function useDeleteProject(): UseMutationResult<void, unknown, string> {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      await Api.delete(`/projects/${id}`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LIST_PROJECTS_CACHE_KEY]),
      onError: (error: unknown, variables: string) => {
        alert(error);
      },
    },
  );
}

export function useDeleteSegment(): UseMutationResult<void, unknown, DeleteSegment, unknown> {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, DeleteSegment, unknown>(
    async (deleteSegment: DeleteSegment) => {
      await Api.delete(`/projects/${deleteSegment.id}/segments/${deleteSegment.segmentId}`);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LIST_PROJECTS_CACHE_KEY]),
      onError: (error: unknown, variables: DeleteSegment, context: unknown) => {
        alert(error);
      },
    },
  );
}

export function useGetUploadUrl(): UseMutationResult<
  ProjectUploadResponse,
  unknown,
  { fileName: string }
> {
  return useMutation(async ({ fileName }) => {
    return await Api.post<ProjectUploadResponse>(`/project/upload-url`, {
      fileName,
    } as unknown as ProjectUploadResponse);
  });
}

export function useProcessClip(): UseMutationResult<void, unknown, { id: string }> {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id }) => {
      return Api.post<void>(`/project/${id}/process`, {} as unknown as void);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LIST_PROJECTS_CACHE_KEY]),
    },
  );
}

export function useUploadVideoToWowza(): UseMutationResult<
  UploadVideoToWowzaResponse,
  unknown,
  { id: string; videoId: string; fileName: string }
> {
  return useMutation<
    UploadVideoToWowzaResponse,
    unknown,
    { id: string; videoId: string; fileName: string }
  >(async ({ id, videoId, fileName }) => {
    return await Api.post<UploadVideoToWowzaResponse>(
      `/projects/${id}/videos/${videoId}/upload-to-wowza`,
      {
        fileName,
      } as unknown as UploadVideoToWowzaResponse,
    );
  });
}

export function useUploadVideoToJwp(): UseMutationResult<
  UploadVideoToWowzaResponse,
  unknown,
  { id: string; videoId: string; fileName: string }
> {
  return useMutation<
    UploadVideoToWowzaResponse,
    unknown,
    { id: string; videoId: string; fileName: string }
  >(async ({ id, videoId, fileName }) => {
    return await Api.post<UploadVideoToWowzaResponse>(
      `/projects/${id}/videos/${videoId}/upload-to-jwp`,
      {
        fileName,
      } as unknown as UploadVideoToWowzaResponse,
    );
  });
}

export function useUploadVideoToDrive(): UseMutationResult<
  UploadVideoToWowzaResponse,
  unknown,
  { projectId: string; email: string; videoUrl: string; type: string }
> {
  return useMutation<
    UploadVideoToWowzaResponse,
    unknown,
    { projectId: string; email: string; videoUrl: string; type: string }
  >(async ({ projectId, email, videoUrl, type }) => {
    return await Api.post<UploadVideoToWowzaResponse>(`/uploadToDrive`, {
      projectId,
      email,
      videoUrl,
      type,
    } as unknown as UploadVideoToWowzaResponse);
  });
}
